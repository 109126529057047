import React from 'react'

const PageHeadingTitle = (data) => {
  return (
    <>
      <h1 className="font-weight-bold text-uppercase text-primary d-inline-block border-bottom" style={{ fontSize: '37px', fontFamily: 'Roboto, serif', lineHeight: '64.75px', margin: 0 }}>
        {data.h1}
      </h1>
      <h2 style={{ fontFamily: 'ITC Korinna Bold,serif', fontSize: '52.5px' }}>
        {data.h2}
      </h2>
    </>
  )
}

export default PageHeadingTitle
